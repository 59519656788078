const headerLinks = [
 {
    name: 'Головна',
    link: '/',
    
 },
 {
   name: 'Положення',
   link: 'standing'
 },
 {
   name: "Регламент",
   link: 'order'
 },
 {
   name: 'судді',
   link: 'personnel'
 },
 {
   name: 'Третейський збір',
   link: 'collecting'
 },
 {
    name: 'Документи',
    link: 'documents/sertificate'
 },
 {
    name: 'Контакти',
    link: 'contacts'
 },

 
]

const documentsLinks = [
   {
      name: 'Свідоцтво',
      link: '/documents/sertificate'
   },
   {
      name: 'Третейська угода',
      link: '/documents/agreement'
   }
]

const aboutLinks = [
   {
      name: 'Третейський збір',
      link: '/about/collecting'
   },
   {
      name: 'Судді',
      link: '/about/judges'
   }
]

export default {headerLinks, documentsLinks, aboutLinks}