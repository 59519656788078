import React, {useState} from 'react';
import { Button } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const BackToTop = () => {
      const [visibleButton, setVisibleButton] = useState(false);

      const scrollToTop = () =>{
          window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
      };
    
      const changeButtonVisible = ()=> {
        if(window.scrollY >= 500) {
          setVisibleButton(true)
        } else {
          setVisibleButton(false)
        }
      }

      window.addEventListener('scroll', changeButtonVisible);


  return (
    <>
      <Button 
        sx={{
          position: 'fixed',
          right: '15px', 
          bottom: '15px',
          border: '1px solid #b39571', 
          color: '#b39571', 
          opacity: visibleButton ? 1 : 0, 
          transition: '.4s all', 
          width: '45px',
          height: '45px',
          padding: 0,
          minWidth: 0,
          zIndex: 11
          }} 
        onClick={scrollToTop}
        >
        <KeyboardArrowUpIcon sx={{fontSize: '18px'}}/>
      </Button>

    </>
  )
}

export default BackToTop
