import React from "react";
import SertificatePhoto1 from "../assets/img/sertificate1.jpg";
import SertificatePhoto2 from "../assets/img/sertificate2.jpg";
import { Container } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from "react-helmet";
const Sertificate = () => {
  return (
    <motion.div
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Свідоцтво</title>
      </Helmet>
      <Container>
      <img style={{ width: "100%" }} src={SertificatePhoto1} />
      <img style={{ width: "100%" }} src={SertificatePhoto2} />
      </Container>
    </motion.div>
  );
};

export default Sertificate;
