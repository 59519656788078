const judgeCard = [
    {
        id: 1,
        name: `Кудінов
Валерій Вікторович`,
        date: '13.12.1968',
        education: `<b>Вища юридична.</b> Закінчив у 1994 році Українську державну юридичну академію /м.Харків/;<br/>
<b>Вища економічна.</b> Закінчив у 2006 році Тернопільський державний економічний університет /м.Тернопіль/`,
        specialty: 'Правознавство; банківська справа.',
        prevWork: 'Адвокат',
        seniority: '32р.',
        majorSeniority: '25р.'
    },
    {
        id: 2,
        name: `Федірко 
Володимир Степанович`,
        date: '17.08.1966',
        education: `<b>Вища юридична.</b> Закінчив у 1991 році Юридичну академію ім. Ф. Дзержинського /м.Харків/`,
        specialty: 'Правознавство',
        prevWork: 'Адвокат',
        seniority: '32р.',
        majorSeniority: '27р.'
    },

    {
        id: 3,
        name: `Кудінова
Тетяна Ігорівна`,
        date: '10.04.1970',
        education: `<b>Bища юридична.</b> Закінчила у 1994 році Українську державну юридичну академію /м.Харків/`,
        specialty: 'Правознавство.',
        prevWork: 'Голова Благодійної організації "Християнський благодійний фонд "Добротвор""',
        seniority: `28р.`,
        majorSeniority: '25р.'
    },
    {
        id: 4,
        name: `Костишин
Василь Михайлович`,
        date: '03.10.1985',
        education: `<b>Вища юридична.</b> Закінчив у 2008 році Одеську Національну юридичну академію /м.Одеса/`,
        specialty: 'Правознавство.',
        prevWork: 'Адвокат',
        seniority: '10p.',
        majorSeniority: '9p.'
    }
];

export default {judgeCard};