import React from 'react';
import HomePageContent from '../components/homePageContent/HomePageContent';
import {motion} from 'framer-motion';
import {animations} from '../constants'

import Promo from '../components/promo/Promo';
import { Helmet } from 'react-helmet';

const Home = () => {

  return (
    <motion.div
    variants={animations.sectionAnimation}
    initial='offscreen'
    animate='onscreen'    >
      <Helmet>
        <title>Третейський суд</title>
      </Helmet>
      <Promo/>
      <HomePageContent/>
    </motion.div>
  )
}

export default Home
