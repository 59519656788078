import React from "react";
import { principal } from "../constants";
import { Typography, Container } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from 'react-helmet';
const Principal = () => {
  return (
    <Container
      sx={{ paddingTop: "90px" }}
      component={motion.div}
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Положення Третейського суду</title>
      </Helmet>
      <Typography
        variantMapping="p"
        sx={{ textAlign: "right", margin: "1em 0", fontWeight: 700 }}
      >
        ЗАТВЕРДЖЕНО
        <br /> рішенням Ради Асоціації «ПРАВОВА ЛІГА»
        <br /> від 22 березня 2007 року, протокол № 1
      </Typography>
      <Typography
        variantMapping="p"
        sx={{ textAlign: "right", fontWeight: 700 }}
      >
        ЗМІНИ ЗАТВЕРДЖЕНО <br />
        рішенням Ради Асоціації «ПРАВОВА ЛІГА»
        <br /> від 09 лютого 2021 року, протокол №1/2021
      </Typography>
      <Typography
        variantMapping="p"
        sx={{ textAlign: "center", fontWeight: 700, marginTop: "1em" }}
      >
        ПОЛОЖЕННЯ
        <br />
        про постійно діючий третейський суд
        <br />
        при Асоціації «ПРАВОВА ЛІГА»
        <br />
        (нова редакція)
        <br />
      </Typography>
      {principal.documentsPrincipalContent.map((article, id) => (
        <Typography
          variantMapping="p"
          key={article.id}
          sx={{
            whiteSpace: "pre-line",
            textAlign: "justify",
            margin: "1em 0",
            fontWeight: article.bold ? 700 : 400,
          }}
        >
          {article.id + `. ` + article.content}
        </Typography>
      ))}
    </Container>
  );
};

export default Principal;
