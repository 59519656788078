import SlidePhoto1 from '../assets/img/bg-test4-min.jpg';
import SlidePhoto2 from '../assets/img/bg-test2-min.jpg';
import SlidePhoto3 from '../assets/img/bg-test1-min.jpg';
import SlidePhoto4 from '../assets/img/bg-test5-min.jpg';

const sliderContent = [
    {
        title: 'Третейський суд',
        content: `Третейський розгляд
спорів сторін`,
        photo: SlidePhoto2
    },
    {
        title: 'Незалежний судовий орган',
        content: `Вирішення цивільних
та господарських спорів`,
        photo: SlidePhoto4
    }
]


export default {sliderContent}