import React from "react";
import { collecting } from "../constants";
import { Typography, Container, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from "react-helmet";
const Collecting = () => {
  return (
    <Container
      sx={{ paddingTop: "90px" }}
      component={motion.div}
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Третейський збір</title>
      </Helmet>
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: { xs: "35px", sm: "40px" },
          fontWeight: 700,
          margin: "0px 0 50px 0",
        }}
      >
        Третейський збір
      </Typography>
      {collecting.collectingContent.map((article, id) => (
        <p
          id={id}
          style={{ whiteSpace: "pre-line", textAlign: "justify" }}
          dangerouslySetInnerHTML={{ __html: article.content }}
        ></p>
      ))}
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: { xs: "35px", sm: "40px" },
          fontWeight: 700,
          margin: "50px 0",
        }}
      >
        Реквізити для сплати третейського збору
      </Typography>

      <Stack sx={{ borderLeft: "5px solid #eee", padding: "10px 20px" }}>
        {collecting.detailsCollecting.map((article, i) => (
          <Typography
            key={i}
            sx={{ whiteSpace: "pre-line", textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></Typography>
        ))}
      </Stack>
    </Container>
  );
};

export default Collecting;
