import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, Container } from "@mui/material";
import { regulations } from "../constants";
import {motion} from 'framer-motion';
import {animations} from '../constants';
import { Helmet } from 'react-helmet';
const Regulations = () => {

  const location = useLocation();
  return (
    <Container sx={{ paddingTop: "90px" }}
    component={motion.div}     
    variants={animations.sectionAnimation}
    initial='offscreen'
    animate='onscreen'  >
      <Helmet>
        <title>Регламент Третейського суду </title>
      </Helmet>
      {regulations.regulationsContent.map((article, id) => (
        <Typography
          variantMapping="body"
          key={id}
          sx={{
            whiteSpace: "pre-line",
            fontWeight: article.bold ? 700 : 400,
            textAlign: article.center ? "center" : article.right ? "right" : "justify",
            margin: "1em 0",
          }}
        >
          
          {article.content}
        </Typography>
      ))}
    </Container>
  );
};

export default Regulations;
