import React from "react";
import { Typography, Container } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from "react-helmet";
const Agreement = () => {
  function handleCopy(event) {
    event.preventDefault();
    const textToCopy = event.target.innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log(`Text "${textToCopy}" copied to clipboard`);
      })
      .catch((error) => console.error(`Could not copy text: ${error}`));
  }
  return (
    <motion.div
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Третейська угода</title>
      </Helmet>
      <Container>
      <Typography variantMapping="p" sx={{ marginTop: "1em" }}>
        Усі спори, розбіжності, вимоги або претензії, що виникають з цього
        Договору, або в зв’язку з ним (при його укладанні, виконанні, зміні,
        розірванні, припиненні, визнанні неукладеним, визнанні недійсним,
        порушенні тощо), підлягають вирішенню у постійно діючому Третейському
        суді при Асоціації «Правова Ліга» (сайт суду – <span onClick={handleCopy} style={{fontWeight: 700, cursor: 'pointer'}}>https://sud.te.ua</span>) у
        відповідності з його Регламентом. Третейський розгляд справи
        здійснюється постійно діючим Третейським судом у складі з одного
        третейського судді. <br />
        Місце знаходження Третейського суду та місце розгляду спору:{" "}
        <b>м. Тернопіль, майдан Волі, 4, поштовий індекс 46001.</b> <br />
        Мова третейського судочинства – <b>українська</b>.<br />
        Сторони є такими, що розуміють правовий зміст та правові наслідки цього
        третейського застереження та вважають їх звернення до Третейського суду
        достатнім заходом захисту своїх прав.
      </Typography>
      </Container>
    </motion.div>
  );
};

export default Agreement;
