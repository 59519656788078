import React from "react";
import { Box, Typography } from "@mui/material";

const FooterCard = ({ title, content, icon, href }) => {
  return (
    <Box
      sx={{
        minHeight: "180px",
        backgroundColor: "#f3f3f3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "30px",
        width: { xs: "100%", md: "100%", lg: "33%" },
      }}
    >
      {icon}
      <Typography
        sx={{
          color: "#b39571",
          fontFamily: "Playfair Display",
          fontSize: "16px",
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
      {href ? (
        <a href={href} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "#303030",
              fontFamily: "PT Sans",
              fontSize: "16px",
              fontWeight: 400,
              whiteSpace: "nowrap",
            }}
          >
            {content}
          </Typography>
        </a>
      ) : (
        <Typography
          sx={{
            color: "#303030",
            fontFamily: "PT Sans",
            fontSize: "16px",
            fontWeight: 400,
            whiteSpace: "nowrap",
          }}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
};

export default FooterCard;
