import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';




const homePageContent = [
    {   
        bold: true,
        content: 'Третейський суд — недержавний незалежний орган, що утворюється за угодою або відповідним рішенням зацікавлених фізичних та/або юридичних осіб у порядку, встановленому Законом України «Про третейські суди» для вирішення спорів, що виникають із цивільних та господарських правовідносин.'
    },
    {
        content: 'Суть третейського судочинства полягає у тому, що сторони довіряють вирішення спору та ухвалення рішення третій особі, а не уповноваженому державою органу. В цьому істотна відмінність третейського судочинства від інших форм захисту прав та інтересів — цивільного та господарського судочинства, які здійснюються виключно судами загальної юрисдикції.'
    },
    {
        content: 'Відповідно до положень ст. 125 Конституції України третейські суди не входять до системи судів загальної юрисдикції, а отже, не є органами правосуддя.'
    },
    {
        content: 'Третейський розгляд спорів сторін у сфері цивільних і господарських правовідносин — це вид недержавної юрисдикційної діяльності, яку третейські суди здійснюють на підставі законів України шляхом застосування, зокрема, методів арбітрування. Здійснення третейськими судами функції захисту прав та інтересів є здійсненням ними не правосуддя, а третейського розгляду спорів сторін у цивільних і господарських правовідносинах у межах права, визначеного Конституцією України.'
    },
    {
        content: 'Хоча третейські суди і не входять до складу судової системи України, рішення третейського суду мають юридичну силу і можуть виконуватися органами державної виконавчої служби у порядку, встановленому для рішень судів загальної юрисдикції.'
    },
    {
        content: "До третейського суду за угодою сторін може бути переданий будь-який спір, що виникає з цивільних та господарських правовідносин, крім випадків, передбачених законом. Якщо чинним міжнародним договором, згода на обов'язковість якого надана Верховною Радою України, встановлений інший порядок організації, діяльності та вирішення спорів третейським судом, ніж той, що передбачено законом про третейські суди, то застосовуються норми міжнародного договору. Дія Закону України «Про третейські суди» не поширюється на міжнародний комерційний арбітраж."
    },
    {
        content: "Третейське судочинство є демократичним альтернативним способом захисту майнових і немайнових прав та охоронюваних законом інтересів фізичних та юридичних осіб та вирішення конфліктів, що виникають між суб'єктами правовідносин, яке ґрунтується на приватному волевиявленні осіб та здійснюється без втручання держави."
    }
];






const footerCards = [
    {
        title: 'Зателефонувати',
        content: '+38 (0352) 52-25-44',
        icon: <CallOutlinedIcon style={{color: '#b39571', fontSize: '50px' }}/>,
        href: 'tel:+380352522544'
    },
    {
        title: 'Напишіть нам',
        content: 'sud.te.ua@gmail.com',
        icon: <EmailOutlinedIcon style={{color: '#b39571', fontSize: '50px' }}/>,
        href: 'mailto:sud.te.ua@gmail.com'
    },
    {
        title: 'Адреса',
        content: 'м. Тернопіль, майдан Волі, буд. 4',
        icon: <LocationOnOutlinedIcon style={{color: '#b39571', fontSize: '50px' }}/>
    }
];


export default {homePageContent, footerCards};