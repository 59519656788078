import emailjs from "@emailjs/browser";

const sendEmail = (templateId, formData, userId, func) => {
    emailjs.send('service_pp72wue', templateId, formData, userId)
      .then((result) => {
        console.log('Email sent successfully!', result);
        func();
      }, (error) => {
        console.log('Email sent failed:', error);
      });
      
  };
export default sendEmail;