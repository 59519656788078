import { createTheme } from '@mui/material/styles';

const myTheme = createTheme({
  breakpoints: {
    values: {
        xl: 1400,
        lg: 1200,
        md: 992,
        sm: 768,
        xs: 0,
    },
  },
});

export default myTheme