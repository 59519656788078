const collectingContent = [
  {
    bold: true,
    content: `При поданні позивачем позовної заяви сплачується третейський збір.`,
  },
  {
    content: `Третейський збір справляється у відсотковому співвідношенні до ціни позову та у фіксованому розмірі у відповідному розмірі прожиткового мінімуму для працездатних осіб, встановленого законом на період, в якому відповідна позовна заява подається до Третейського суду.

    <b>Третейський збір</b> обчислюється у таких розмірах:
- позовної заяви майнового характеру - 1 відсоток ціни позову, але не менше 1 розміру прожиткового мінімуму для працездатних осіб.
- позовної заяви немайнового характеру – 1 розмір прожиткового мінімуму для працездатних осіб.`,
  },
  {
    content: `За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.
При зменшенні ціни позову сплачений третейський збір не повертається.
`,
  },
];

const detailsCollecting = [
  {
    content: `ОДЕРЖУВАЧ: Асоціація «ПРАВОВА ЛІГА»
КОД ОДЕРЖУВАЧА: 34826691
РАХУНОК: UA 263253650000002600901169442
НАЗВА БАНКУ: ПрАТ «Кредобанк»
МФО: 325365`,
  },
  {
    content: `Призначення платежу:
Третейський збір за розгляд позову <u>(назва підприємства)</u> до <u>(назва підприємства)</u> .Без ПДВ.`,
  },
];

export default { collectingContent, detailsCollecting };
