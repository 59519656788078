import React from "react";
import "./style.css";

import PromoPhoto from "../../assets/img/bg-test4.jpg";
import {slider} from '../../constants'
import { Box, Typography, Stack, Container} from "@mui/material";
import Slider from "react-slick";
import Slide from "./Slide";

const Promo = () => {

  const sliderSetting = {
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 600,
    dots: false,
    fade: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  }


  return (
    <Slider {...sliderSetting}>
      {slider.sliderContent.map((slide, id) => (
        <Slide id={id} title={slide.title} content={slide.content} photo={slide.photo} />
      ))}
    </Slider>


  );
};

export default Promo;
