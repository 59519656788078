import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Container,
  Stack,
  Box,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import "./style.css";
import { data } from "../../constants";
import HomePagePhotoContent from "../../assets/img/home-page-content.jpg";

const HomePageContent = () => {
  return (
    <Container sx={{ marginTop: "60px" }}>

      {/* НОВИЙ ЗРАЗОК ВИГЛЯДУ ГОЛОВНОЇ СТОРІНКИ */}
      {/* <Grid container spacing={4}>

        <Grid item md={5} sm={5} xs={12} sx={{display: {xs: 'flex', sm: 'block'}, justifyContent: {xs: 'center', sm: 'flex-start'}}}>
          <Paper
            sx={{
              boxShadow: "none",
              position: "relative",
            }}
          >
            <Box
              sx={{
                maxWidth: 450,
                position: "relative",
                "&:after": {
                  content: '" "',
                  width: "100%",
                  position: "absolute",
                  height: "100%",
                  backgroundColor: "#cab397",
                  left: "10px",
                  top: "10px",
                },
              }}
            >
              <img
                src={HomePagePhotoContent}
                alt=""
                style={{
                  width: "100%",
                  zIndex: 10,
                  position: "relative",
                }}
              />
            </Box>

            <Box
              sx={{
                maxWidth: 450,
                position: "absolute",
                bottom: 0,
                left: 0,
                padding: {md: '0px 45px 70px', xs: '0 20px 40px;'},
                zIndex: 21,
              }}
            >
              <Typography
                className="title"
                variant="h2"
                sx={{
                  fontWeight: 400,
                  fontSize: {md: "2.25rem", sm: '1.8rem', xs: '1.56rem'},
                  color: "#fff",
                  marginBottom: "25px",
                  "&::first-letter" : {
                    fontSize: {md: '7.5rem', sm: '6rem', xs: '5rem'}
                  }
                }}
              >
                Захист прав <br />
                та інтересів сторін
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {md:  "0.875rem", sm: '0.6rem', xs: '0,75rem'},
                  letterSpacing: {md: '6px', xs: '4px'},
                  textTransform: "uppercase",
                }}
              >
                Третейський суд
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={1} sx={{display: {md: 'none', sm: 'block', xs: 'none'}}}/>
        <Grid item md={7} sm={6} >
          <Box sx={{ padding: {lg: '30px 0 0 45px', sm: '0 0 0 0', xs: '70px 0 0 0'}}}>
            <Typography
              fontWeight="700"
              fontSize={{md: "1.2rem", sm: '1rem'}}
              sx={{
                lineHeight: "1.67em",
                textAlign: "justify",
                marginBottom: "1.2em",
              }}
            >
              Постійно діючий Третейський суд при Асоціації "Правова Ліга" - це недержавний незалежний орган, що утворюється за
              угодою або відповідним рішенням зацікавлених фізичних та/або
              юридичних осіб у порядку, встановленому Законом України «Про
              третейські суди» для вирішення спорів, що виникають із цивільних
              та господарських правовідносин.
            </Typography>
            <Typography
              fontSize={{md: "1.1rem", sm: '1rem'}}
              sx={{
                lineHeight: "1.67em",
                textAlign: "justify",
                marginBottom: "1.2em",
              }}
            >
              Суть третейського судочинства полягає у тому, що сторони довіряють
              вирішення спору та ухвалення рішення третій особі, а не
              уповноваженому державою органу. В цьому істотна відмінність
              третейського судочинства від інших форм захисту прав та інтересів
              — цивільного та господарського судочинства, які здійснюються
              виключно судами загальної юрисдикції.
            </Typography>
            <Link
              to="standing"

              style={{
                display: 'inline-block',
                padding: "11px 25px",
                backgroundColor: "#cab397",
                width: "auto",
                textDecoration: 'none',
                color: '#fff',
                borderRadius: '10px'
              }}
            >
              Положення Третейського суду
            </Link>
          </Box>
        </Grid>
      </Grid> */}

      {/* КОНТЕНТ ГОЛОВНОЇ СТОРІНКИ ЗІ СТАРОГО зразку */}
      {data.homePageContent.map((article, index) => (
        <Typography
        key={index}
          fontWeight={article.bold ? '700' : '400'}
          sx={{textAlign: 'justify',
              whiteSpace: 'pre-line',
              marginTop: '45px',
              fontFamily: 'PT Sans',
              fontSize: article.bold ? '20px' : '18px',
              lineHeight: '30px'         
          }}
        >
          {article.content}
        </Typography>
      ))}
    </Container>
  );
};

export default HomePageContent;
