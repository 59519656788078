import React from "react";
import { Modal, Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const ModalWindowResult = ({ modalOpen, handleClose }) => {
  const styles = {
    modalWindow: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: 24,
      padding: '50px 25px 30px 25px'
    }
  };
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Stack sx={styles.modalWindow}>
          <Typography sx={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', fontSize: 32}}>Дякуємо за заявку</Typography>
          <Typography sx={{textAlign: 'center', marginTop: '10px'}}>Ми зв'яжемось з вами у найближчий час</Typography>
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 9,
              right: 9,
              borderRadius: "100%",
              backgroundColor: "#fff",
              minWidth: "35px !important",
              height: "35px",
              padding: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: "35px", color: "#000" }} />
          </Button>
        </Stack>

      </Modal>
    </>
  );
};

export default ModalWindowResult;
