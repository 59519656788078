import React, { useState } from "react";
import { Stack, Grid, Typography, Container, Button } from "@mui/material";
import FormPhoto from "../../assets/img/bg-test4-min.jpg";
import sendEmail from "../../constants/emailjs";
import ModalWindowResult from "../modalWindowResult/ModalWindowResult";

const FooterFormHomePage = () => {
  // Створюємо State для модального вікна ,
  // і функції для відкривання/закривання

  const [modalResult, setModalResult] = useState(false);

  const handleOpenResult = () => setModalResult(true);
  const handleCloseResult = () => setModalResult(false);

  // Створюємо State для даних для подальшої відправки на пошту,
  // Створюмо функцію handleSubmit для відправки даних по пошті,
  // Створюємо функцію handleChange для обробки даних з форми і записуємо їх у обєкт

  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail("template_t33gx63", formData, "EwjZXftZljw3v3PRM", handleOpenResult);
    console.log(formData);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <>
        <Stack
      sx={{
        background: `url(${FormPhoto}) center top/cover no-repeat fixed`,
        padding: "100px 0",
        position: "relative",
        marginTop: "100px",
      }}
      className="footer__overlay"
    >
      <Container sx={{ zIndex: 10 }}>
        <Grid container>
          <Grid item lg={7} md={8} xs={12}>
            <Typography color="#fff" sx={{ fontSize: 35 }}>
              Замовте безкоштовну консультацію
            </Typography>
            <Typography color="#fff" sx={{ fontSize: 25, marginTop: "20px" }}>
              Подзвоніть нам за телефоном <b>+38 (0352) 52-25-44</b> або
              заповніть нижченаведену форму, щоб отримати професійну та
              конфіденційну консультацію.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4} sx={{ marginTop: "20px" }}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <input
                    type="text"
                    name="name"
                    style={{ width: "100%" }}
                    placeholder="Ваше ім'я"
                    className="form__input"
                    onChange={handleChange}
                  ></input>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <input
                    type="text"
                    name="phone"
                    style={{ width: "100%" }}
                    placeholder="Ваш телефон"
                    className="form__input"
                    onChange={handleChange}
                  ></input>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <input
                    type="text"
                    name="email"
                    style={{ width: "100%" }}
                    placeholder="Ваш email"
                    className="form__input"
                    onChange={handleChange}
                  ></input>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <textarea
                    type="text"
                    name="message"
                    style={{ width: "100%", resize: "none" }}
                    placeholder="Опис справи..."
                    className="form__input form__textarea"
                    onChange={handleChange}
                  ></textarea>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Button
                    sx={{
                      border: "1px solid #fff",
                      color: "#fff",
                      fontSize: "25px",
                    }}
                    type="submit"
                  >
                    Замовити
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Stack>
    <ModalWindowResult modalOpen={modalResult} handleClose={handleCloseResult} />
    </>
  );
};

export default FooterFormHomePage;
