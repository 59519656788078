import React, { useState } from "react";
import { Modal, Typography, Button, Box, Stack } from "@mui/material";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import ModalPhoto from "../../assets/img/bg-test1-min.jpg";
import sendEmail from "../../constants/emailjs";
import ModalWindowResult from "../modalWindowResult/ModalWindowResult";

const ModalWindowForm = ({ modal, handleClose }) => {
  // Створюємо State для модального вікна ,
  // і функції для відкривання/закривання

  const [modalResult, setModalResult] = useState(false);

  const handleOpenResult = () => setModalResult(true);
  const handleCloseResult = () => setModalResult(false);

  // Створюємо State для даних для подальшої відправки на пошту,
  // Створюмо функцію handleSubmit для відправки даних по пошті,
  // Створюємо функцію handleChange для обробки даних з форми і записуємо їх у обєкт

  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail("template_1rkztuq", formData, "EwjZXftZljw3v3PRM", handleOpenResult);
    console.log(formData);
    handleClose();
    
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Задаємо стилі Модальному вікну і формі

  const styles = {
    modalWindow: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: { md: 780, sm: 600, xs: 300 },
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: 24,
      flexDirection: "row",
    },
    formInput: {
      padding: "10px 0 10px 10px",
      border: "1px solid #c1baba",
    },
    formButton: {
      padding: "10px 0",
      backgroundColor: "#b39571",
      border: "none",
      borderRadius: "5px",
      height: "50px",
      fontSize: "20px",
      color: "#fff",
    },
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Stack sx={styles.modalWindow}>
          <Box sx={{ padding: { md: "32px", sm: "15px", xs: "10px" } }}>
            <Typography
              variant="h3"
              component="h2"
              sx={{ textAlign: "center", fontSize: { md: 40, xs: 30 } }}
            >
              Замовте безкоштовну консультацію
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                mt: 2,
                fontSize: { md: 16, xs: 13 },
                textAlign: "center",
                lineHeight: 1.4,
              }}
            >
              Подзвоніть нам за телефоном <br />
              +38 (0352) 52-25-44 або заповніть нижченаведену форму, щоб
              отримати професійну та конфіденційну консультацію.
            </Typography>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
                gap: "20px",
              }}
              onSubmit={handleSubmit}
            >
              <input
                name="name"
                type="text"
               
                style={styles.formInput}
                required
                className="form__input"
                placeholder="Ваше ім'я"
                onChange={handleChange}
              />
              <input
                name="phone"
                type="tel"
                pattern="[0-9]{10}"
                style={styles.formInput}
                required
                className="form__input"
                placeholder="Ваш телефон"
                onChange={handleChange}
              />
              <input type="submit" style={styles.formButton} required />
            </form>
            <Button
              onClick={handleClose}
              sx={{
                width: "100%",
                marginTop: "20px",
                padding: "10px 0",
                backgroundColor: "#f3f3f3",
                color: "#909090",
                fontSize: "15px",
              }}
            >
              Ні, дякую
            </Button>
          </Box>
          <img src={ModalPhoto} className="modal__img" />
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 9,
              right: 9,
              borderRadius: "100%",
              backgroundColor: "#fff",
              minWidth: "35px !important",
              height: "35px",
              padding: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: "35px", color: "#000" }} />
          </Button>
        </Stack>
      </Modal>
      <ModalWindowResult modalOpen={modalResult} handleClose={handleCloseResult}/>
    </>
  );
};

export default ModalWindowForm;
