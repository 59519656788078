const documentsPrincipalContent = [
    {
        id: 1,
        content: 'Загальні положення',
        bold: true
    },
    {
        id: 1.1,
        content: 'У відповідності з Законом України «Про третейські суди» та Статутом Асоціації «ПРАВОВА ЛІГА» — Постійно діючий третейський суд при Асоціації «ПРАВОВА ЛІГА» (в подальшому — Третейський суд) є недержавним незалежним постійно діючим органом, що утворений для вирішення спорів, які виникають із цивільних та господарських правовідносин.'
    },
    {
        id: 1.2,
        content: "Третейський суд утворено рішенням Ради Асоціації «ПРАВОВА ЛІГА» у порядку, встановленому Законом України «Про третейські суди» та здійснює свою діяльність відповідно до чинного законодавства, що регулює діяльність третейських судів в Україні, Регламентом Третейського суду та цим Положенням."
    },
    {
        id: 1.3,
        content: "Третейський суд не має статусу юридичної особи."
    },
    {
        id: 1.4,
        content: "Третейський суд провадить свою діяльність відповідно до Закону України «Про третейські суди» та цього Положення. Діяльність Третейського суду не є підприємницькою діяльністю."
    },
    {
        id: 1.5,
        content: "Порядок та правила вирішення спорів у Третейському суді визначаються Законом України «Про третейські суди» та Регламентом Третейського суду."
    },
    {
        id: 1.6,
        content: "Матеріально-технічне забезпечення діяльності Третейського суду здійснюється його засновником."
    },
    {
        id: 1.7,
        content: "Утворення і діяльність Третейського суду, а також суми коштів або вартість майна, одержаних Асоціацією «ПРАВОВА ЛІГА» як третейський збір чи на покриття інших витрат, пов'язаних з вирішенням спору Третейським судом відповідно до Закону України «Про третейські суди» та цього Положення, не є доходами Асоціації «ПРАВОВА ЛІГА» від здійснення підприємницької діяльності."
    },
    {
        id: 1.8,
        content: `Найменування Третейського суду: \n\
Повне найменування: \n\
- Постійно діючий третейський суд при Асоціації «ПРАВОВА ЛІГА». \n\
Скорочене найменування: \n\
- Третейський суд при Асоціації «ПРАВОВА ЛІГА». `
    },
    {
        id: 1.9,
        content: "Місцезнаходженням Третейського суду є місцезнаходження його засновника – Асоціації «ПРАВОВА ЛІГА»: 46001, майдан Волі, 4, м. Тернопіль, Україна."
    },
    {
        id: 2,
        content: "Засновник Третейського суду",
        bold: true
    },
    {
        id: 2.1,
        content: "Засновником Третейського суду є Асоціація «ПРАВОВА ЛІГА» (ідентифікаційний код 34826691, зареєстрована 25.12.2006р.)."
    },
    {
        id: 2.2,
        content: "Місцезнаходження Асоціації «ПРАВОВА ЛІГА»: 46001, майдан Волі, 4, м. Тернопіль, Україна."
    },
    {
        id: 2.3,
        content: "Засновник не має права втручатися в діяльність Третейського суду та суддів, яка пов'язана з розглядом справ."
    },
    {
        id: 2.4,
        content: `Засновник має наступні права та обов'язки: \n\
- затверджує Положення, Регламент Третейського суду та Список третейських суддів;
- затверджує зміни та доповнення до Положення, Регламенту Третейського суду та Списку третейських суддів;
- виключає суддів Третейського суду зі Списку третейських суддів;
- публікує Положення та Регламент Третейського суду;
- забезпечує зберігання справ та документів Третейського суду;
- виплачує третейським суддям гонорар у сумі та в порядку, передбаченому Регламентом Третейського суду;
- здійснює оплату праці працівників секретаріату Третейського суду у сумі та в порядку, передбаченому трудовими договорами (угодами);
- вирішує інші питання, які віднесені до його компетенції чинним законодавством, цим Положенням та Регламентом Третейського суду.`
    },
    {
        id: 2.5,
        content: "Засновник бере на себе зобов'язання щодо матеріально-технічного забезпечення діяльності Третейського суду, для чого він: надає Третейському суду приміщення, оргтехніку, канцелярські товари та ін.; своєчасно забезпечує Третейський суд коштами, які йому необхідні для здійснення витрат, пов’язаних з вирішенням спору."
    },
    {
        id: 3,
        content: "Мета, завдання і принципи організації та діяльності Третейського суду",
        bold: true
    },
    {
        id: 3.1,
        content: "Третейський суд створений з метою справедливого, швидкого та ефективного правосуддя при розгляді спорів та примиренні осіб, які до нього звернулися."
    },
    {
        id: 3.2,
        content: "Третейський суд здійснює свою діяльність на засадах самоуправління, добровільності, гласності та самофінансування. Третейський суд не ставить за мету одержання прибутків від своєї діяльності."
    },
    {
        id: 3.3,
        content: "Завданням Третейського суду є захист майнових і немайнових прав та охоронюваних законом інтересів фізичних чи юридичних осіб шляхом всебічного неупередженого розгляду та вирішення спорів відповідно до закону."
    },
    {
        id: 3.4,
        content: "Третейський суд утворено і його діяльність ґрунтується на принципах, визначених Законом України «Про третейські суди»."
    },
    {
        id: 4,
        content: "Компетенція Третейського суду",
        bold: true
    },
    {
        id: 4.1,
        content: "На розгляд до Третейського суду може бути передано будь-який спір, що виник з цивільних чи господарських правовідносин, крім випадків, передбачених Законом України «Про третейські суди» та іншими законодавчими актами."
    },
    {
        id: 4.2,
        content: "Спір може бути переданий на розгляд до Третейського суду за наявності між сторонами третейської угоди, яка відповідає вимогам Закону України «Про третейські суди»."
    },
    {
        id: 4.3,
        content: "Спір може бути переданий на вирішення Третейського суду до прийняття компетентним судом рішення у спорі між тими ж сторонами, з того ж предмета і з тих самих підстав."
    },
    {
        id: 5,
        content: "Організація (склад) Третейського суду",
        bold: true
    },
    {
        id: 5.1,
        content: "Третейський суд складається з голови Третейського суду, заступника голови Третейського суду (за необхідністю), третейських суддів, що включені до списку третейських суддів, а також секретаріату (за необхідністю)."
    },
    {
        id: 5.2,
        content: "Третейський суд очолює голова Третейського суду."
    },
    {
        id: 5.3,
        content: "Голова Третейського суду обирається Радою Асоціації «ПРАВОВА ЛІГА» строком на 10 (десять) років з числа осіб, включених до списку третейських суддів Третейського суду."
    },
    {
        id: 5.4,
        content: "Головою Третейського суду може бути обрана фізична особа, яка має вищу юридичну освіту, досвід роботи за юридичною спеціальністю не менше 5 (п'яти) років, а також має знання, досвід, ділові та моральні якості, необхідні для розгляду спорів і здійснення безпосереднього керівництва роботою Третейського суду."
    },
    {
        id: 5.5,
        content: " За необхідністю, Рада Асоціації «ПРАВОВА ЛІГА» може обрати заступника голови Третейського суду. Заступник голови Третейського суду обирається строком на 10 (десять) років з числа осіб, включених до списку третейських суддів Третейського суду."
    },
    {
        id: 5.6,
        content: "Заступником голови Третейського суду може бути обрана фізична особа, яка має вищу юридичну освіту, досвід роботи за юридичною спеціальністю не менше 5 (п'яти) років, а також має знання, досвід, ділові та моральні якості, необхідні для розгляду спорів."
    },
    {
        id: 5.7,
        content: `Голова Третейського суду, його заступник, у відсутність голови Третейського суду, представляють Третейський суд без спеціального доручення у відносинах з органами державної влади та органами місцевого самоврядування, підприємствами, установами та організаціями в Україні та за її кордоном.
При організації діяльності Третейського суду, голова Третейського суду та його заступник виконують функції, передбачені цим Положенням та Регламентом Третейського суду.
Голова Третейського суду, його заступник, у відсутність голови Третейського суду, видають розпорядження з питань, що належать до його компетенції.`
    },
    {
        id: 5.8,
        content: "Голова Третейського суду та заступник голови Третейського суду мають право бути третейськими суддями при розгляді спорів Третейським судом."
    },
    {
        id: 5.9,
        content: "Третейські судді Третейського суду обираються Радою Асоціації «ПРАВОВА ЛІГА» строком на 10 (десять) років."
    },
    {
        id: 5.10,
        content: "Третейським суддею може бути обрана фізична особа, яка має вищу юридичну освіту, досвід роботи за юридичною спеціальністю не менше 5 (п'яти) років, а також має знання, досвід, ділові і моральні якості, необхідні для розгляду спорів."
    },
    {
        id: 5.11,
        content: "Затверджений Радою Асоціації «ПРАВОВА ЛІГА» список обраних третейських суддів подається до відповідного органу державної реєстрації."
    },
    {
        id: 5.12,
        content: "Третейські судді не можуть бути представниками сторін. Третейським суддею може бути призначена чи обрана особа, яка прямо чи опосередковано не заінтересована в результаті вирішення спору, а також має визнані сторонами знання, досвід, ділові та моральні якості, необхідні для вирішення спору."
    },
    {
        id: 5.13,
        content: `Суддями Третейського суду не можуть бути:
- особи, які не досягли повноліття, та особи, які перебувають під опікою чи піклуванням;
- особи, які не мають кваліфікації, погодженої сторонами безпосередньо чи визначеної у регламенті третейського суду;
- особи, які мають судимість;
- особи, визнані в судовому порядку недієздатними;
- судді судів загальної юрисдикції або Конституційного Суду України.`
    },
    {
        id: 5.14,
        content: `Особа може бути виключена із списку третейських суддів за рішенням Ради Асоціації «ПРАВОВА ЛІГА» у таких випадках:
1) за письмовою заявою особи у разі неможливості виконання повноважень третейського судді за станом здоров'я чи з інших причин;
2) у разі систематичного невиконання або неналежного виконання повноважень третейського судді без поважних причин;
3) у разі розголошення конфіденційної інформації щодо спорів, які розглядаються у Третейському суді;
4) у разі порушення вимог щодо несумісності;
5) у разі набрання законної сили обвинувальним вироком суду щодо неї;
6) у разі набрання законної сили судовим рішенням про визнання її обмежено дієздатною чи недієздатною;
7) у разі її смерті, визнання її безвісно відсутньою або оголошення померлою рішенням суду, що набрало законної сили.
Рішення про виключення особи із списку третейських суддів приймається за поданням голови Третейського суду.`
    },
    {
        id: 5.15,
        content: `Третейський суд, за необхідністю, може мати секретаріат, який складається з секретарів секретаріату або, за необхідністю, може укладати угоду з секретарем по окремим справам.`
    },
    {
        id: 5.16,
        content: "Секретаріат, у випадку його створення, здійснює функції, необхідні для забезпечення діяльності Третейського суду відповідно до цього Положення та Регламенту Третейського суду. Секретаріат очолює відповідальний секретар."
    },
    {
        id: 5.17,
        content: "Працівники секретаріату Третейського суду приймаються на роботу в Асоціацію «ПРАВОВА ЛІГА» або запрошуються за угодою для участі в окремих справах."
    },
    {
        id: 5.18,
        content: "Відповідальний секретар організовує діяльність секретаріату, веде діловодство, пов'язане з діяльністю Третейського суду, а також виконує інші передбачені цим Положенням та Регламентом Третейського суду функції."
    },
    {
        id: 5.19,
        content: "Відповідальний секретар Третейського суду призначається Президентом Асоціації «ПРАВОВА ЛІГА». Розподіл обов'язків між відповідальним секретарем, секретарями секретаріату, здійснює відповідальний секретар."
    },
    {
        id: 6,
        content: "Самоврядування третейських суддів",
        bold: true
    },
    {
        id: 6.1,
        content: "Для представництва та захисту інтересів третейських суддів Третейського суду утворюються органи третейського самоврядування."
    },
    {
        id: 6.2,
        content: "Органом самоврядування третейських суддів Третейського суду є збори третейських суддів, які скликаються по мірі необхідності, але не менше одного разу на рік. Про скликання зборів третейських суддів Третейського суду, судді повідомляються не пізніше ніж за 10 днів до дати їх проведення."
    },
    {
        id: 6.3,
        content: "Головою зборів третейських суддів є голова Третейського суду."
    },
    {
        id: 6.4,
        content: `До компетенції зборів третейських суддів відносяться:
1) аналіз практики правозастосування при розгляді спорів Третейським судом;
2) питання організації методичної роботи;
3) обрання делегатів для участі у заходах, здійснюваних в рамках самоврядування третейських суддів;
4) надання рекомендацій Президенту Асоціації «ПРАВОВА ЛІГА» щодо включення кандидатур у список третейських суддів;
5) інші питання, які стосуються діяльності Третейського суду та внесені до порядку денного зборів Третейського суду на вимогу не менше як 1/3 від загальної кількості третейських суддів.`
    },
    {
        id: 6.5,
        content: "Збори третейських суддів є правомочними, якщо на них присутні не менше як 2/3 від загальної кількості третейських суддів."
    },
    {
        id: 6.6,
        content: "Рішення зборів третейських суддів є прийнятими в разі, якщо за них проголосувала більшість із третейських суддів, присутніх на зборах."
    },
    {
        id: 6.7,
        content: "Порядок ведення зборів третейських суддів та порядок денний зборів визначається головою зборів. До порядку денного включаються також будь-які питання, якщо цього вимагають не менше як 1/3 від загальної кількості третейських суддів."
    },
    {
        id: 7,
        content: "Припинення діяльності Третейського суду",
        bold: true
    },
    {
        id: 7.1,
        content: `Діяльність Третейського суду може бути припинена на підставі:
- рішення Засновника;
- ліквідації Засновника;
- рішення компетентного суду про скасування (визнання недійсною) державної реєстрації;
- з інших підстав, передбачених законодавчими актами України.`
    },
    {
        id: 7.2,
        content: "У разі прийняття рішення компетентного суду про скасування (визнання недійсною) державної реєстрації Третейський суд вважається таким, що припинив свою діяльність з моменту набрання сили таким рішенням компетентного суду."
    },
    {
        id: 7.3,
        content: "У разі прийняття рішення Радою Асоціації «ПРАВОВА ЛІГА» про припинення діяльності Третейського суду, або у разі припинення діяльності Асоціації «ПРАВОВА ЛІГА» Третейський суд вважається таким, що припинив свою діяльність з моменту внесення відомостей про це до реєстру постійно діючих третейських судів."
    },
    {
        id: 7.4,
        content: "Діяльність Третейського суду не може бути припинена за рішенням Ради Асоціації «ПРАВОВА ЛІГА» до розгляду ним останнього спору, що знаходиться у його провадженні."
    },
    {
        id: 7.5,
        content: "У місячний термін з дати прийняття рішення про припинення діяльності Третейського суду Засновник подає до органу державної реєстрації Третейського суду необхідні документи про припинення діяльності Третейського суду, а також забезпечує опублікування цього рішення."
    },
    {
        id: 8,
        content: "Заключні положення",
        bold: true
    },
    {
        id: 8.1,
        content: "Справи, розглянуті Третейським судом, зберігаються у Третейському суді протягом 10 (десяти) років з дня прийняття рішення Третейським судом."
    }

]

export default {documentsPrincipalContent};