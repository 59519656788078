import React, { useState } from "react";
import { Container, Stack, Typography, Grid, Button } from "@mui/material";
import emailjs from "@emailjs/browser";
import { data } from "../../constants";
import "./footer.css";
import FooterCard from "./FooterCard";
import ModalWindowResult from "../modalWindowResult/ModalWindowResult";

import { useLocation } from "react-router-dom";
import FooterFormHomePage from "./FooterFormHomePage";
import FooterFormCommon from "./FooterFormCommon";

const Footer = () => {
  const location = useLocation();

  // Створюємо State для модального вікна,
  // Сворюємо функцію для відкриття/закриття модального вікна
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {location.pathname === "/" || location.pathname === "/contacts" ? (
        <FooterFormHomePage />
      ) : null}
      <Container>
        <Stack>
          {/* Форма зразку зі старого сайту */}
          {/* {location.pathname === "/" ? null : (
            <FooterFormCommon />
          )} */}
          {/* /////////////  CARDS ON FOOTER AND COPYRIGHT ////////// */}
          {location.pathname === "/" || location.pathname === "/contacts" ? (
            <Stack
              direction={{ sm: "column", md: "column", lg: "row" }}
              gap="30px"
              sx={{ marginTop: "50px" }}
              justifyContent="space-between"
            >
              {data.footerCards.map((card, index) => (
                <FooterCard
                  key={index}
                  title={card.title}
                  content={card.content}
                  icon={card.icon}
                  href={card.href}
                />
              ))}
            </Stack>
          ) : null}
          <Typography
            sx={{
              marginTop: "50px",
              opacity: 0.5,
              color: "#000",
              fontFamily: "PT Sans",
              fontSize: "14px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            © 2020 Всі права захищені.
          </Typography>
        </Stack>
        <ModalWindowResult modalOpen={modal} handleClose={closeModal} />
      </Container>
    </>
  );
};

export default Footer;
