import React, { useState } from "react";
import { Modal, Typography, Button, Box, Stack } from "@mui/material";
import "./style.css";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ModalPhoto from "../../assets/img/bg-test1-min.jpg";
import CloseIcon from "@mui/icons-material/Close";
import ModalWindowForm from "./ModalWindowForm";

const ModalWindow = () => {

  // Створюємо State для модального вікна ,
  // і функції для відкривання/закривання
  
  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  // Створюємо State для кнопки яка буде відкривати модальне вікно,
  // Створюємо функцію для показу кнопки на екран при скролі

  const [visibleButton, setVisibleButton] = useState(false);

  const changeButtonVisible = () => {
    if (window.scrollY >= 1000) {
      setVisibleButton(true);
    } else {
      setVisibleButton(false);
    }
  };

  window.addEventListener("scroll", changeButtonVisible);


  return (
    <>

      <Button
        onClick={handleOpen}
        sx={{
          position: "fixed",
          right: "15px",
          bottom: "100px",
          backgroundColor: 'none',
          color: '#b39571',
          border: "1px solid #b39571",
          opacity: visibleButton ? 1 : 0,
          transition: ".4s all",
          width: '45px',
          height: '45px',
          padding: 0,
          minWidth: 0,
          zIndex: 11
        }}
      >
        <PhoneIcon sx={{ fontSize: "25px" }} />
      </Button>
     
      <ModalWindowForm modal={modal} handleClose={handleClose}/>
    </>
  );
};

export default ModalWindow;
