import { Routes, Route, Link } from "react-router-dom";
import React from "react";
import { CssBaseline } from "@mui/material";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";

import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import Principal from "./pages/Principal";
import Documents from "./pages/Documents";
import Regulations from "./pages/Regulations";
import BackToTop from "./components/backToTop/BackToTop";
import Sertificate from "./pages/Sertificate";
import Agreement from "./pages/Agreement";
import Collecting from "./pages/Collecting";
import Judges from "./pages/Judges";
import Contacts from "./pages/Contacts";
import ModalWindow from "./components/modalWindow/ModalWindow";
import ModalWindowResult from "./components/modalWindowResult/ModalWindowResult";

import { ThemeProvider } from "@mui/material/styles";
import myTheme from './customThemeMUI';
function App() {
  return (
    <ThemeProvider theme={myTheme}>
      <div className="App">
        <CssBaseline />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="standing" element={<Principal />}></Route>
          <Route path="order" element={<Regulations />}></Route>
          <Route path="personnel" element={<Judges />}></Route>
          <Route path="collecting" element={<Collecting />}></Route>
          <Route path="documents" element={<Documents />}>
            <Route path="sertificate" element={<Sertificate />}></Route>
            <Route path="agreement" element={<Agreement />}></Route>
          </Route>
          {/* <Route path="about" element={ <About /> }>
          <Route path="judges" element={< Judges/>}></Route>
          <Route path="collecting" element={< Collecting/>}></Route>
        </Route> */}
          <Route path="contacts" element={<Contacts />} />
        </Routes>
        <BackToTop />
        <ModalWindow />

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
