import React from "react";
import {
  Container,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import JudgePhoto from "../assets/img/judge_example.jpg";
import JudgeCard from "../components/judgeCard/JudgeCard";
import { judges } from "../constants";
import "../App.css";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from 'react-helmet';
const Judges = () => {
  return (
    <Container
      sx={{ paddingTop: "90px" }}
      component={motion.div}     
      variants={animations.sectionAnimation}
      initial='offscreen'
      animate='onscreen'  
    >
      <Helmet>
        <title>Склад Третейського суду</title>
      </Helmet>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ background: "#ebebf1" }}>
              <TableCell>№</TableCell>
              <TableCell>
                Прізвище, імя <br /> та по батькові
              </TableCell>
              <TableCell>
                Дата
                <br /> народження
              </TableCell>
              <TableCell>Освіта</TableCell>
              <TableCell>
                Отримана <br /> спеціальність
              </TableCell>
              <TableCell>
                Останнє <br /> місце роботи
              </TableCell>
              <TableCell>
                Загальний
                <br /> трудовий <br />
                стаж
              </TableCell>
              <TableCell>
                Стаж роботи
                <br /> за спеціальністю
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {judges.judgeCard.map((card, id) => (
              <TableRow key={id}>
                <TableCell>{card.id}</TableCell>
                <TableCell>{card.name}</TableCell>
                <TableCell>{card.date}</TableCell>
                <TableCell dangerouslySetInnerHTML={{__html: card.education}}></TableCell>
                <TableCell>{card.specialty}</TableCell>
                <TableCell sx={{textAlign: 'center'}}>{card.prevWork}</TableCell>
                <TableCell>{card.seniority}</TableCell>
                <TableCell>{card.majorSeniority}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Grid container spacing={3}>
        {judges.judgeCard.map((card, id) => (
          <JudgeCard
            id={id}
            name={card.name}
            date={card.date}
            education={card.education}
            speciality={card.specialty}
            seniority={card.seniority}
            majorSeniority={card.majorSeniority}
          />
        ))}
      </Grid> */}
    </Container>
  );
};

export default Judges;
