const sectionAnimation = {
    offscreen: {
        opacity: 0
    },
    onscreen: {
        opacity: 1
    }
};

const promoAnimation = {
    offscreen: {
        y: -1000
    },
    onscreen: {
        y: 0
    }
}

export default {sectionAnimation, promoAnimation}