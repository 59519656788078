import React from "react";
import PromoPhoto from "../../assets/img/bg-test4.jpg";
import { Box, Typography, Stack, Container } from "@mui/material";

const Slide = ({title, content, photo}) => {
  const styles = {
    paperContainer: {
      position: 'relative',
      background: `url(${photo}) center center / cover no-repeat`,
      height: {md: 700, sm: 600, xs: 450},
      padding: "160px 0",
      justifyContent: 'center',
      alignItems: 'center'
    },
    styledSlideContent: {
      background: "rgba(40, 46, 63, 0.3)",
      width: "fit-content",
      padding: "10px",
      color: "#fff",
      whiteSpace: "pre-wrap",
      fontSize: {lg: '55px', md: '45px', sm: '35px', xs: '30px'}
    },
  };

  return (
    <Stack component="div" sx={styles.paperContainer} className="paper__container">
      <Container className="slide__container">
        <Typography className="slide__title">{title}</Typography>
        <Stack>
          <Typography
            variant="h2"
            sx={styles.styledSlideContent}
            className="slide__content"
          >
            {content}
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Slide;
