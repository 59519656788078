import React from "react";
import { Container, Typography, Stack, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { collecting } from "../constants";
import { Helmet } from "react-helmet";
const Contacts = () => {
  const styles = {
    cardTitle: {
      fontSize: "26px",
    },
    cardDescr: {
      fontSize: "18px",
    },
    card: {
      padding: "30px",
      border: "1px solid #efeeee",
      width: "100%",
    },
    cardWrapper: {
      marginTop: "50px",
    },
  };

  return (
    <Container
      sx={{ marginTop: "90px" }}
      component={motion.div}
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Контактна інформація Третейського суду</title>
      </Helmet>
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: "40px",
          fontWeight: 700,
        }}
      >
        Контактна інформація
      </Typography>

      <Grid container sx={styles.cardWrapper}>
        <Grid item md={6} sm={12} xs={12}>
          <Stack sx={styles.card}>
            <Typography sx={styles.cardTitle}>Адреса</Typography>
            <Typography sx={styles.cardDescr}>
              м. Тернопіль, майдан Волі, 4, поштовий індекс 46001
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Stack sx={styles.card}>
            <Typography sx={styles.cardTitle}>
              Голова третейського суду
            </Typography>
            <Typography sx={styles.cardDescr}>
              Кудінов Валерій Вікторович
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Stack sx={styles.card}>
            <Typography sx={styles.cardTitle}>Телефон</Typography>
            <Typography sx={styles.cardDescr}>+38 (0352) 52-25-44</Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Stack sx={styles.card}>
            <Typography sx={styles.cardTitle}>E-mail</Typography>
            <Typography sx={styles.cardDescr}>sud.te.ua@gmail.com</Typography>
          </Stack>
        </Grid>
      </Grid>


      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: "40px",
          fontWeight: 700,
          margin: "50px 0",
        }}
      >
        Розміщення на карті
      </Typography>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2588.3634544104266!2d25.589371000000003!3d49.553165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473036b5110c8b11%3A0x858f62faa46b0734!2z0LzQsNC50LTQsNC9INCS0L7Qu9GWLCA0LCDQotC10YDQvdC-0L_RltC70YwsINCi0LXRgNC90L7Qv9GW0LvRjNGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCA0NjAwMg!5e0!3m2!1suk!2sua!4v1673462692356!5m2!1suk!2sua"
        style={{ border: 0, width: "100%", height: "600px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </Container>
  );
};

export default Contacts;
