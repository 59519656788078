import React from "react";
import { NavLink } from "react-router-dom";
import { links } from "../../constants";
import { Stack, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const MobileMenu = ({ active, handleClose }) => {
  return (
    <>
      <Stack className={active ? "menu active" : "menu"} sx={{ gap: "20px" }}>
        {links.headerLinks.map((link, id) => (
          <NavLink
            onClick={handleClose}
            key={link.id}
            to={link.link}
            className={({ isActive }) =>
              isActive ? "mobile__link active" : "mobile__link"
            }
          >
            {link.name}
          </NavLink>
        ))}
        <Button sx={{ position: "absolute", top: 20, right: 20, color: '#000' }} onClick={handleClose} >
          <CloseIcon sx={{fontSize: '2rem'}}/>
        </Button>
      </Stack>
    </>
  );
};

export default MobileMenu;
